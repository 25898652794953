import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { FacebookLoginButton } from "react-social-login-buttons";
import { LoginSocialFacebook } from "reactjs-social-login";
const FaceBookSign = () => {
  const params = useParams();
  const application = params.application;
  const key = params.key;
  const facebookButtonRef = useRef(null);
  useEffect(() => {
    console.log(facebookButtonRef.current);
    if (facebookButtonRef.current) {
      console.log("This is working");
      facebookButtonRef.current.click();
    }
  }, [facebookButtonRef]);
  return (
    <div>
      <LoginSocialFacebook
        appId={process.env.REACT_APP_FACEBOOK_APP_ID}
        onResolve={async (respond) => {
          const response = await fetch(`/api/${application}:${key}`, {
            method: "POST",
            body: JSON.stringify(respond),
            headers: { "content-type": "application/json" },
          });
          console.log(await response.json());
        }}
        onReject={(error) => {
          console.error(error);
        }}
      >
        <FacebookLoginButton ref={facebookButtonRef} />
      </LoginSocialFacebook>
    </div>
  );
};

export default FaceBookSign;
