import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import React, { useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
const GoogleSign = () => {
  const googleButtonRef = useRef(null);
  const params = useParams();
  const application = params.application;
  const key = params.key;
  useEffect(() => {
    if (googleButtonRef.current) {
      googleButtonRef.current.click();
    }
  }, []);
  return (
    <div>
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
        <GoogleLogin
          id="googleLoginButton"
          onSuccess={async (credentialResponse) => {
            const decoded = jwtDecode(credentialResponse.credential);
            const response = await fetch(`/api/${application}:${key}`, {
              method: "POST",
              body: JSON.stringify(decoded),
              headers: { "content-type": "application/json" },
            });
            console.log(await response.json());
          }}
          onError={() => {
            console.log("Login Failed");
          }}
          ref={googleButtonRef}
        />
      </GoogleOAuthProvider>
    </div>
  );
};

export default GoogleSign;
