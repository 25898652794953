import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const Facebook = () => {
  const { name } = useParams();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`/api/${name}`);

        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        if (data) {
          const [application, key] = data.split(":");
          window.location.href = `/facebook/retriev/${application}/${key}`;
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Call the async function
  }, [name]);

  return <div>Loading...</div>;
};

export default Facebook;
