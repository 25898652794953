import "./App.css";
import { useEffect, useRef } from "react";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import GoogleSign from "./page/GoogleSign";
import FaceBookSign from "./page/FaceBookSign";
import Home from "./page/Home";
import Google from "./containers/Google";
import Facebook from "./containers/Facebook";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/google/:name",
    element: <Google />,
  },
  {
    path: "/facebook/:name",
    element: <Facebook />,
  },
  {
    path: "/google/retriev/:application/:key",
    element: <GoogleSign />,
  },
  {
    path: "/facebook/retriev/:application/:key",
    element: <FaceBookSign />,
  },
]);
function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
